/*

  File: components/blog/headerLink.js
  Kind: Component
  Description: Previous / Next Blog Post Navigation
*/

import React from 'react';

import { Link } from 'gatsby';

//Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../util/fontawesome.js';

const HeaderLink = ({ direction, slug, title }) => {

  const icons = {
    prev: 'angle-double-left',
    next: 'angle-double-right',
  };

  const flexDir = {
    prev: 'flex-row',
    next: 'flex-row-reverse'
  }

  if (slug) {
    const path = '/blog/' + slug;
    const icon = icons[direction];
    return (
      <div>
        <Link to={path} className="group text-saturn hover:text-neptune">
          <div className={`flex ${flexDir[direction]} items-center justify-end`}>
            <FontAwesomeIcon icon={['fas', icon]} className="text-saturn-100 group-hover:text-neptune" />
            <div className="mx-2">{title}</div>
          </div>
        </Link>
      </div>
    );
  } else {
    //return nothing…
    return '';
  }
};

export default HeaderLink;
