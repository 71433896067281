/*

  File: components/blog/header.js
  Kind: Component
  Description: Header component which shows titles / excerpt(description?) and previous, next blogs
*/

import React from 'react';

import HeaderLink from './headerLink.jsx';

//Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../util/fontawesome.js';

const BlogHeader = ({ title, excerpt, date, nextPost, prevPost }) => {
  const Divider =
    prevPost && nextPost ? (
      <div>
        <FontAwesomeIcon icon={['fas', 'grip-lines-vertical']} className="text-saturn-100" />
      </div>
    ) : (
      ''
    );

  return (
    <div className="grid grid-cols-1 mb-8 md:grid-cols-6 md:grid-rows-2 gap-4">
      <div id="blogTitle" className="md:col-span-5">
        <div className="flex flex-col">
          <div className="font-code text-saturn-700 text-xs">title:</div>
          <div className="font-serif font-normal text-4xl text-saturn-200">{title}</div>
        </div>
      </div>
      <div id="blogDate" className="">
        <div className="flex flex-col items-end">
          <div className="font-code text-saturn-700 text-xs">timestamp:</div>
          <div className="font-serif text-saturn-200 text-md">{date}</div>
        </div>
      </div>
      <div id="blogExcerpt" className="md:col-span-4 row-span-2">
        <div className="flex flex-col">
          <div className="font-code text-saturn-700 text-xs">excerpt:</div>
          <div className="font-serif font-normal text-jupiter-100">{excerpt}</div>
        </div>
      </div>
      <div id="blogNav" className="md:col-span-2 justify-self-end">
        <div className="flex flex-col">
          <div className="font-code text-saturn-700 text-xs self-end">navigation: </div>
          <div className="flex flex-row">
            <HeaderLink
              direction="prev"
              slug={prevPost && prevPost.fields.slug}
              title={prevPost && prevPost.frontmatter.title}
            />
            {Divider}
            <HeaderLink
              direction="next"
              slug={nextPost && nextPost.fields.slug}
              title={nextPost && nextPost.frontmatter.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
