import React from 'react';
import { useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

//Components
import Layout from '../../components/layout.js';
import BlogHeader from '../../components/blog/header.jsx';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Prism from 'prismjs';

export default function Template({ data, pageContext, location }) {
  const { mdx } = data;
  const { frontmatter, body, excerpt } = mdx;

  const ImagesCatalog =
    frontmatter.images &&
    frontmatter.images.filter(f=>f).reduce((a, c) => {
      const catalog = c.relativeDirectory.split('/').splice(-1)[0];
      if (!a[catalog]) {
        a[catalog] = {};
      }
      a[catalog][c.name] = c;
      return a;
    }, {});

    console.log(ImagesCatalog)

  // console.log('FRONTMATTER:', frontmatter);

  const heroImage = () => {
    if (frontmatter.featured_image !== null && frontmatter.featured_image !== undefined) {
      return (
        <div>
          <div className="font-code text-saturn-700 text-xs mb-2">heroImage:</div>
          <GatsbyImage image={frontmatter.featured_image.childImageSharp.gatsbyImageData} className="mb-8" alt="" />
        </div>
      );
    } else {
      return '';
    }
  };

  // console.log('PAGE_CONTEXT:', pageContext);

  useEffect(() => {
    // call the highlightAll() function to style our code blocks
    Prism.highlightAll();
  });

  return (
    <Layout
      pageTitle={`${frontmatter.title.toLowerCase()} | essays `}
      location={location}
      metaImage={frontmatter.featured_image && frontmatter.featured_image.social}
    >
      <BlogHeader
        title={frontmatter.title}
        excerpt={frontmatter.excerpt || excerpt}
        date={frontmatter.date}
        nextPost={pageContext.next}
        prevPost={pageContext.prev}
      />
      {heroImage()}
      <div className="font-code text-saturn-700 text-xs">content:</div>
      <MDXRenderer images={ImagesCatalog}>{body}</MDXRenderer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featured_image {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
          social: childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        images {
          relativeDirectory
          name
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
